/* eslint-disable */

// see : https://stackoverflow.com/a/58712964/14457261
export const formator = (nombre, max = 6, min = 2) => {
    if (min > max) return 'formatorERR';
    nombre = new Intl.NumberFormat(
        'fr-FR',
        {
            maximumFractionDigits: max,
            minimumFractionDigits: min,
        }
    ).format(nombre);

    return nombre;    
}