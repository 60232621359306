/* eslint-disable */
import Layout from "@/layout/Layout.vue";

const adminRoutes = {
  path: '/administrator',
  component: Layout,
  // redirect: '/administrator/users',
  name: 'Administrator',
  alwaysShow: true,
  meta: {
    title: 'Administration',
    icon: 'admin',
    permissions: ['view admin menu'],
    roles: ['admin'],
    noCache: true,
    activeMenu: '/article/list',
  },
  children: [
    // voir un utilisateur
    {
      path: 'users/edit/:id(\\d+)',
      component: () => import('@/views/users/Profile'),
      name: 'UserProfile',
      meta: {
        title: 'Modification utilisateur',
        noCache: true,
        permissions: ['view users','edit users']
      },
      hidden: true,
    },

    // afficher la liste des utilisateurs
    {
      path: 'users',
      component: () => import('@/views/users/Users'),
      name: 'UserList',
      meta: { title: 'Utilisateurs', noCache: true, icon: 'peoples', permissions: ['view adminusers menu'] },
    },

    // afficher le dashboard de gestion des droits (Role + Permissions)
    {
      path: 'dash',
      component: () => import('@/views/permission/DashPermission'),
      name:'perm;',
      meta: {
        title: 'Gestion des droits',
        noCache: true,
        icon: 'lock',
        permissions: ['view adminroles menu'],
      },
    },
  ],
};

export default adminRoutes;
