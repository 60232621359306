import Layout from '@/layout/Layout.vue';

const perm = ['view stats menu']; // todo ajouter perm

const stats = {
  path: '/stats',
  component: Layout,
  name: 'dashStats',
  meta: {
    title: 'Statistiques', // pour le fil d'Arianne
    permissions: perm,
  },

  // no submenu, because children.length == 1
  children: [
    // Accéder à la page de renouvèllement des services
    {
      path: '/stats',
      component: () => import('@/views/stats/dashStats.vue'),
      meta: {
        title: 'Statistiques',
        icon: 'el-icon-data-line',
        permissions: perm,
        noCache: true,
      },
    }
  ],
};

export default stats;