<!-- eslint-disable -->
<template>
  <div v-if="!item.hidden&&item.children" class="menu-wrapper wr">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :id="onlyOneChild.meta ? 'menu' + onlyOneChild.meta.title : ''" :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <item v-if="onlyOneChild.meta" :icon="onlyOneChild.meta.icon||item.meta.icon" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else :index="resolvePath(item.path)">
      <template slot="title">          
        <div @click="goTo(item.path)"><item v-if="item.meta" :icon="item.meta.icon" :title="isNest ? '' : item.meta.title" /></div>
      </template>

      <template v-for="child in visibleChildren">
        <sidebar-item
          v-if="child.children&&child.children.length>0"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />
        <app-link v-else :key="child.name" :to="resolvePath(child.path)">
          <el-menu-item :index="resolvePath(child.path)">
            <item v-if="child.meta" :icon="child.meta.icon" :title="child.meta.title" />
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>

    <el-divider v-if="is_group" class="my-divider"></el-divider>
  </div>
</template>

<script>
/* eslint-disable */
import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from './Item';
import AppLink from './Link';
// import { generateTitle } from '@/utils/i18n';

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onlyOneChild: null,
      is_group    : false,
    };
  },
  created(){
    //               si on est sur un groupe de client exranet  OU  sur un groupe de route intranet
    this.is_group = (this.item.meta && this.item.meta.is_group) || (this.item && this.item.children ? !this.hasOneShowingChild(this.item.children,this.item) : false) ? true : false;
  },
  computed: {
    visibleChildren() {
      return this.item.children.filter(item => !item.hidden);
    },
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return isExternal(routePath);
    },
    goTo(route){
      if (route){
        // récupération de l'id de groupe depuis le path
        const regex  = /\/extranet\/grp\/(\d+)$/;
        const string = route;
        const match  = string.match(regex);
        if (!match){
          if(this.$node_env == 'development'){
            console.log("Pas de correspondance de grp, ce n'est pas une erreur si navigation depuis l'intranet");
          }
          return;
        }

        var cible = '/extranet/dashGroup/';
        var id    = match[1];
        route     = cible + id;

        // Error: Avoided redundant navigation to current location
        // https://stackoverflow.com/a/64485378/14457261
        if (typeof(route) == "string") {
          if (this.$route.path != route) {
              this.$router.push(route);
          }
        }
      } else {
        console.warn('error in goTo func.')
        console.warn(route)
      }
    },
    // generateTitle,
  },
};
</script>
<style lang="css">
.el-menu-item{
  text-align: left;

  /* Ajout de la gestion de text-overflow */
  text-overflow: ellipsis !important;  
  /* Les deux règles suivantes sont nécessaires pour text-overflow */
  white-space: nowrap;
  overflow: hidden;
}

.my-divider{
  margin: 0 0 0 0 !important;
  /* filter: brightness(75%); */
  /* background-color: #02264A !important;
  color: #02264A !important; */
}
</style>

