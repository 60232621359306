<template>
  <div>
      <el-button @click="goBack" :type="type" :plain="plain" icon="el-icon-back" >
          {{ innerText ? innerText : ""}}
      </el-button>
      <el-button @click="goFront" :type="type" :plain="plain" icon="el-icon-right" >
          {{ innerText ? innerText : ""}}
      </el-button>
  </div>
</template>

<script>
/* eslint-disable */

export default {
name: "BackButtonComponent",
props: {
  // dataperso: {
  //   type: undefined,
  // },
  innerText: {
    type: String,
  },
  type: {
    type: String,
  },
  plain: {
    type: Boolean,
  },
  
  round: {
    type: Boolean,
  },
},
components: {},
data() {
  return {};
},
created() {},

methods: {
  goBack() {
      this.$router.go(-1)
  },
  goFront() {
      this.$router.go(+1)
  },
},
};
</script>