<template>
    <div class="navbar">
        <hamburger
            id="hamburger-container"
            :is-active="sidebar.opened"
            class="hamburger-container"
            @toggleClick="toggleSideBar"
        />

        <!-- value-key="nav_bar_nom_aff" pour afficher un texte dans l'input après recherche-->
        <!-- Empêcher la barre de recherche centre au clients # 518-->
        <div v-if="!$checkRoles(['extranet client'])">
          <!-- si permission de chercher des clients OU des factures -->
          <el-autocomplete
            v-if="$checkPerms(['browse all comptes']) || $checkPerms(['browse all invoices'])"
            :debounce="50"
            id="autocomplete"
            title="Recherchez . . ."
            :popper-append-to-body="true"
            popper-class="my-autocomplete"          
            highlight-first-item
            :fetch-suggestions="letSearch"
            @select="selectAutocomplete"
            v-model="query.keyword"
            placeholder="Recherchez . . ."
            size="mini"
            class="left-menu-search"
            :prefix-icon="searchicon"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            ref="input_ref"
          >


            <!-- au changement de valeur, on met le focus sur le champ d' <el-autocomplete ...> pour le laisser lancer le fetch et son callback  -->
            <!-- si permission de chercher des clients OU des factures -->
            <el-select v-model="query.select" slot="append" placeholder="Client" style="width:100px !important;" @change="()=>(this.$refs.input_ref.focus() )">
              <!-- si permission de chercher des clients -->
              <el-option v-if="$checkPerms(['browse all comptes'])" label="Client"  value="cli"></el-option>
              <!-- si permission de chercher des factures -->
              <el-option v-if="$checkPerms(['browse all invoices'])" label="Facture" value="fact"></el-option>
            </el-select>

            <template slot-scope="{ item }">
              <!-- permet de click molette -->
              <router-link :to="'/account/show/' + item.id" style="display: inline-block;width:100%">
                <span v-if="query.select == 'cli'">
                  <span v-html="item.nav_bar_nom_aff"></span>
                </span>
                <span v-else-if="query.select == 'fact'">
                  Facture : {{ item.nav_bar_nom_aff }}
                  <el-divider direction="vertical"></el-divider>
                  {{ item.nom_aff }}
                </span>
              </router-link>
            </template>
          </el-autocomplete>

        </div>

        <div v-else-if="$checkRoles(['extranet client'])">
          <!-- si permission de chercher des clients OU des factures -->
          <el-autocomplete
            v-if="$checkPerms(['extranet browse all comptes']) || $checkPerms(['extranet browse all invoices'])"
            :debounce="50"
            id="autocomplete"
            title="Recherchez . . ."
            :popper-append-to-body="true"
            popper-class="my-autocomplete"          
            highlight-first-item
            :fetch-suggestions="letSearchExtranet"
            @select="selectAutocompleteExtranet"
            v-model="query.keyword"
            placeholder="Recherchez . . ."
            size="mini"
            class="left-menu-search"
            :prefix-icon="searchicon"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            ref="input_ref"
          >

            <!-- au changement de valeur, on met le focus sur le champ d' <el-autocomplete ...> pour le laisser lancer le fetch et son callback  -->
            <!-- si permission de chercher des clients OU des factures -->
            <el-select v-model="query.select" slot="append" placeholder="Compte" style="width:105px !important;" @change="()=>(this.$refs.input_ref.focus() )">
              <!-- si permission de chercher des clients -->
              <el-option v-if="$checkPerms(['extranet browse all comptes'])" label="Compte"  value="cli"></el-option>
              <!-- si permission de chercher des factures -->
              <el-option v-if="$checkPerms(['extranet browse all invoices'])" label="Facture" value="fact"></el-option>
            </el-select>

            <template slot-scope="{ item }">

              <span v-if="query.select == 'cli'">
              Compte : {{ item.nav_bar_nom_aff }}
              </span>

              <span v-else-if="query.select == 'fact'">
              Facture : {{ item.nav_bar_nom_aff }}
                <el-divider direction="vertical"></el-divider>
                {{ item.nom_aff }}
              </span>

            </template>

          </el-autocomplete>

        </div>

        <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
        <div class="right-menu">
            <screenfull
              id="screenfull"
              class="right-menu-item hover-effect"
            />
            <el-dropdown
              trigger="hover"
              placement="bottom"
              style="margin-left: auto;margin-right: 0;"
              :hide-timeout="500"
              class="avatar-container right-menu-item">

              <span class="avatar-container right-menu-item hover-effect">
                <i class="el-icon-user"></i>
              </span>

              <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item disabled style="color: #7f7f7f !important; font-weight: bolder !important;">
                    <b>{{ prenom + " " + name }}</b>
                  </el-dropdown-item>
                  <router-link v-show="userId !== null" :to="`/profile/edit`" v-if="$checkPerms(['extranet view self users', 'view self users'])">
                    <el-dropdown-item divided>
                      Profil
                    </el-dropdown-item>
                  </router-link>
                  <el-dropdown-item divided>
                    <span style="display:block;" @click="logout">
                      Déconnexion
                    </span>
                  </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import Resource from "@/api/resource";

export default {
    name: "NavbarView",
    components: {
        Breadcrumb,
        Hamburger,
        Screenfull
    },
    computed: {
        ...mapGetters([
            "sidebar",
            "name",
            "prenom",
            "userId"
        ])
    },
    created() {

      // si permission de chercher des clients mais pas des factures
      if( this.$checkPerms(['browse all comptes']) && !this.$checkPerms(['browse all invoices']) ){
        this.query.select = "cli";
      
      // si permission de chercher des factures mais pas des clients
      } else if ( this.$checkPerms(['browse all invoices']) && !this.$checkPerms(['browse all comptes']) ) {
        this.query.select = "fact";

      // si permission de chercher des clients et des factures
      } else if ( this.$checkPerms(['browse all comptes']) && this.$checkPerms(['browse all invoices']) ) {
        this.query.select = "cli";

      } else if( this.$checkRoles(['extranet client']) ){
        
        // si permission de chercher des clients mais pas des factures
        if( this.$checkPerms(['extranet browse all comptes']) && !this.$checkPerms(['extranet browse all invoices']) ){
          this.query.select = "cli";
        
        // si permission de chercher des factures mais pas des clients
        } else if ( this.$checkPerms(['extranet browse all invoices']) && !this.$checkPerms(['extranet browse all comptes']) ) {
          this.query.select = "fact";

        // si permission de chercher des clients et des factures
        } else if ( this.$checkPerms(['extranet browse all comptes']) && this.$checkPerms(['extranet browse all invoices']) ) {
          this.query.select = "cli";

        }

      } else {
        this.query.select = "";
      }
    },
    data() {
      return {
        empty      : true,
        searchicon : 'el-icon-search',
        loading    : false,
        identifieur: null,
        list       : [],
        searchList : [],
        query      : {
          page   : 1,
          limit  : 30,
          keyword: "",
          select : '',
          showActive:[1],
        },
      };
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch("app/toggleSideBar");
      },
      async logout() {
        await this.$store.dispatch("user/logout");
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        
      },
      async letSearch(queryString, cb) {
        this.searchicon = 'el-icon-loading';
        this.loading = true;
        cb( await this.getList() );
      },
      async letSearchExtranet(queryString, cb) {
        this.searchicon = 'el-icon-loading';
        this.loading = true;
        cb( await this.getListExtranet() );

      },
      selectAutocomplete(data) {

        let cli_id = data.id;

        // on catch si la recherche sort d'un mot clé de facture
        if ( data.showFact ){
          this.$router.push( '/account/show/' + cli_id +'?ShowFactures=1' );

        } else {
          this.$router.push( '/account/show/' + cli_id );
        }
      },
      selectAutocompleteExtranet(data) {

        let cli_id = data.id;

        // on catch si la recherche sort d'un mot clé de facture
        if ( data.showFact ){
          this.$router.push( '/extranet/dash/' + cli_id +'?ShowFactures=1' );

        } else {
          this.$router.push( '/extranet/dash/' + cli_id );
        }
      },

      async getList(/*queryString, cb*/) {
        
        this.loading = true;
        console.log("getList");
        const cliResource = new Resource("clients");
        const {data/*, meta*/} = await cliResource.list(this.query);
        
        console.log("get prospect Account list : ");
        console.log(data);
        
        this.list = data;

        this.searchicon = 'el-icon-search';
        this.loading = false;
        this.identifieur = null;

        return this.list;
      },
      async getListExtranet(/*queryString, cb*/) {
        
        this.loading = true;
        console.log("getListExtranet");
        const cliResource = new Resource("extranet/search/clients");
        const {data/*, meta*/} = await cliResource.list(this.query);
        
        console.log("get extranet Account list : ");
        console.log(data);
        
        this.list = data;

        this.searchicon = 'el-icon-search';
        this.loading = false;
        this.identifieur = null;

        return this.list;
      },
    }
};
</script>

<!-- eslint-disable -->

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .left-menu-search {
    float: right;
    width: 350px;
    height: 100%;
    line-height: 50px;
    float: left;
    margin: 0 20px 0 0;
    vertical-align: text-bottom;
  }


  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .link {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
</style>