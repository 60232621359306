import Layout from '@/layout/Layout.vue';

const perm = ['view compare ca menu'];

const compareCA = {
  path: '/compareCA',
  component: Layout,
  name: 'CA_commerciaux',
  meta: {
    title: 'CA - commerciaux', // pour le fil d'Arianne
    permissions: perm,
  },

  // no submenu, because children.length == 1
  children: [
    // Accéder à la page de renouvèllement des services
    {
      path: '/compareCA',
      component: () => import('@/views/compareCA/DashCompareCA.vue'),
      meta: {
        title: 'CA - commerciaux',
        icon: 'el-icon-suitcase-line',
        permissions: perm,
        noCache: true,
      },
    }
  ],
};

export default compareCA;