<template>
    <div id="app">
        <router-view />
    </div>
</template>


<script>
export default {
    name: "app"
};
</script>


<style lang="scss">
@import "styles/index";

* {
    word-break: normal !important;
}

div.el-autocomplete-suggestion.el-popper.my-autocomplete{
    min-width: 450px !important;
}

// gestion des bouttons n'ayant pas de classe par défaut
// .el-button--default{
//     border          : 1px solid $--color-primary !important;
//     border-color    : $--color-primary !important;
//     color           : $--color-primary !important;

//     &.is-disabled{
//         background-color: mix(transparent, grey, 50) !important;    // mix entre fond transparent et la couleur désirée
//     }
// }
// .el-button--default:hover{
//     border-color    : $--color-primary !important;
//     color           : $--color-primary !important;
// }


// // gestion des bouttons type text
// .el-button--text{
//     color           : $--color-primary !important;
// }
// .el-button--text:hover{
//     color           : $--color-success !important;
// }


// // gestion des bouttons ayant la classe primary + gestion des hover et plain
// .el-button--primary{
//     background-color: $--color-primary !important;
//     border-color    : $--color-success !important;
//     color           : #fff !important;

//     &:hover{
//         background-color: $--color-success !important;
//         border-color    : $--color-primary !important;
//         color           : $--color-primary !important;
//     }

//     &.is-plain{
//         background-color: mix(white, $--color-primary, 20) !important;    // mix entre fond blanc et la couleur désirée
//         border-color    : $--color-success !important;
//         color           : #fff !important;
     
//         &:hover{
//             background-color: $--color-success !important;
//             border-color    : $--color-primary !important;
//             color           : $--color-primary !important;
//         }
//     }

//     &.is-disabled{
//         background-color: mix(transparent, $--color-primary, 40) !important;    // mix entre fond transparent et la couleur désirée
//         border-color    : $--color-success !important;
//         color           : #fff !important;
//     }

// }


// // gestion des bouttons ayant la classe success + gestion des hover et plain
// .el-button--success{
//     background-color: $--color-success !important;
//     border-color    : $--color-primary !important;
//     color           : #fff !important;

//     &:hover{
//         background-color: mix(white, $--color-success, 40) !important;    // mix entre fond blanc et la couleur désirée
//         border-color    : $--color-success !important;
//         color           : $--color-primary !important;
//     }

//     &.is-plain{
//         background-color: mix(white, $--color-success, 40) !important;    // mix entre fond blanc et la couleur désirée
//         border-color    : $--color-primary !important;
//         color           : $--color-primary !important;
     
//         &:hover{
//             background-color: $--color-success !important;
//             border-color    : $--color-success !important;
//             color           : #fff !important;
//         }
//     }

//     &.is-disabled{
//         background-color: mix(transparent, $--color-success, 40) !important;    // mix entre fond transparent et la couleur désirée
//         border-color    : $--color-primary !important;
//         color           : #fff !important;
//     }
// }


// gestion des el-select
.el-select-dropdown__item{
    color           : $--color-primary !important;
}


// gestion des el-select
.el-dropdown-menu__item{
    color           : $--color-primary !important;
}


// gestion des icone jaunes
.el-icon-warning{
    color           : $--color-warning !important;
}


// gestion des liens
.el-link--default{
    color           : auto;
}
.el-link--default:hover{
    color           : $--color-success !important;
}

.el-link--primary{
    color           : $--proso-dark !important;
}
.el-link.el-link--primary.is-underline{
    &:hover{
        color       : $--proso-light !important;
    }
}
.el-link.is-underline:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  bottom: 0;
  border-bottom: 1px solid $--proso-dark !important;
}
// .el-link--primary:visited{
//     color           : $--color-success !important;
// }

.el-link--success{
    color           : $--color-success !important;
}

.el-link--warning{
    color           : $--color-warning !important;
}

.el-link--danger{
    color           : $--color-danger !important;
}

.el-link--info{
    color           : $--color-info !important;
}


// gestion des radio button
.el-radio-button__orig-radio:hover + .el-radio-button__inner {
    background-color: $--color-success !important;
    color           : $--color-primary;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: $--color-primary !important;
    border-color    : $--color-success !important;
    color           : #fff !important;
    box-shadow      : none !important;
}
.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: 0 0 2px 2px $--color-success !important;
}
.el-radio__input.is-checked + .el-radio__label{
    color           : $--color-primary !important;
}
.el-radio__input.is-checked .el-radio__inner{
    border-color: $--color-primary !important;
    background  : $--color-primary !important;
}
.el-radio__inner:hover {
  border-color: $--color-primary !important;
}

// gestion du menu de gauche
.el-menu-item:hover{
    color           : #fff !important;
}
#app .sidebar-container .is-active > .el-submenu__title:hover{
    color           : #fff !important;
}

// le code ci dessous il met en évidence un item de menu lorsque celui ce est replié
.el-submenu {
    &.is-active{
        &.is-opened{
            background-color: #fff !important;
        }
        &:not(.is-opened){
            // background-color: $--proso-dark !important;
            > .el-submenu__title {
                > * {
                    color: #409EFF !important;
                    &:hover{
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

// gestion des input
// .el-input.is-active .el-input__inner, .el-input__inner:focus {
//   border-color: $--color-primary !important;
//   outline: 0;
// }


// gestion menu el-tab
.el-tabs__active-bar{
  background-color: $--proso-light !important;
}
div.el-tabs__item.is-top.is-active{
  color: $--proso-dark !important;
}
</style>
