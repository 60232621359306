import Layout from '@/layout/Layout.vue';

const perm = ['view gestion num fixe menu'];

const gestionNumFixe = {
  path: '/gestionNumFixe',
  component: Layout,
  name: 'Dash_GestionNumFixe',
  meta: {
    title: 'Gestion des num. fixe', // pour le fil d'Arianne
    permissions: perm,
  },

  // no submenu, because children.length == 1
  children: [
    // Accéder à la page de renouvèllement des services
    {
      path: '/gestionNumFixe',
      component: () => import('@/views/gestionNumFixe/DashGestionNumFixe.vue'),
      meta: {
        title: 'Gestion des num. fixe',
        icon: 'el-icon-phone',
        permissions: perm,
        noCache: true,
      },
    }
  ],
};

export default gestionNumFixe;