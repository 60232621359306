/* eslint-disable */
import store from '@/store';

export const checkRoles = (value) => {

  const roles = store.getters && store.getters.roles;

  // Si on veut voir que du contenus de l'extranet
  if( value != 'extranet client' ){ 
    if(roles.includes('super admin')) return true;
  }

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const Roles = value;

      const hasRole = roles.some(role => {
        return Roles.includes(role);
      })

      return hasRole;

    } else {
      console.warn(`\ncheckRoles need no empty roles! Like "['super admin','editor']"\n`);
      return false;
    }
  } else {
    console.warn(`\ncheckRoles need roles! Like "['super admin','editor']"\n`);
    return false;
  }
}