<template>
    <div
        style="height:40vh"
        class="home"
        v-loading="loading"
        element-loading-text="Redirection. . ."
        element-loading-background="rgba(0, 0, 0, 0.0)">

        <div style="display: flex; justify-content: center; margin-top: 50px;">
            <el-image src="/images/logo-prosoluce-better-new-transp.png" fit="cover" alt="Logo Prosoluce" style="width: 600px;" />
        
        </div>

        <!-- see: https://vincentgarreau.com/particles.js/ -->

    </div>
   
</template>

<script>
/* eslint-disable */
import store from "@/store";                 // Before first // https://stackoverflow.com/a/69418661/14457261
import state from "@/store/modules/user.js"; // Then import the module


export default {
    name: "HomeView",
    watch: {
        $router: {
            handler: function(routes) {
                
                // Objectif : Rediriger l'utilisateur extranet vers son 1er compte dispo, plutot que de le laisser sur le dashboard
                //  => quant il y aura un vrais dashboard, on pourra supprimer le contenu de ce watcher
                // si le role == extranet client
                // on va recupérer les routes dispo par ses droits
                // on ca récupérer les routes dispo sur le router vue
                // on va lui afficher directement le 1er groupe dispo ou 1er compte dispo si pas de groupe

                // si le role == extranet client
                if (this.$checkRoles(['extranet client'])){
                    const allRoutes = routes.getRoutes();
                    
                    // on va recupérer les routes dispo par ses droits
                    const extranet_routes = state.state.extranet_routes;

                    // on stock si il y a des routes extranet de groupe &&/|| extranet de client
                    var extranet_grp_routes = [];
                    var extranet_cli_routes = [];

                    extranet_routes.forEach(element => {
                        if (element.is_group){
                            extranet_grp_routes.push(element);
                        } else {
                            extranet_cli_routes.push(element);
                        }
                    });

                    // si on a au moins 1 route extranet de type groupe de client
                    if (extranet_grp_routes.length > 0){

                        // là extranet_grp_routes contient les routes de type groupe de client triée dans l'ordre alphabétique par l'api
                        // on sait quil y a au moins une route groupe a afficher => on l'affiche direct  => mais on vérifie si le routeur accepte cette route avant
                        // si non on va chercher pour une route extranet PAS groupe                      => mais on vérifie si le routeur accepte cette route avant

                        // on vérifie si le routeur accepte cette route avant
                        const baseGrpRoute = '/extranet/dashGroup/' ;
                        const route2Go     = baseGrpRoute + extranet_grp_routes[0].id;
                        const routeToCheck = baseGrpRoute + ":id_grp(\\d+)";
                        const routeExists  = allRoutes.some(route => route.path === routeToCheck);

                        if (routeExists) {
                            // console.log(`La route extranet groupe ${routeToCheck} existe dans votre application.`);
                            this.$router.push(route2Go);
                        } /*else {
                            console.log(`La route extranet groupe ${routeToCheck} n'existe pas dans votre application.`);
                        }*/

                        this.loading = false;
                        return;

                    // si on a au moins 1 route extranet de type client direct
                    } else if (extranet_cli_routes.length > 0){

                        // là extranet_cli_routes contient les routes de type client direct triée dans l'ordre alphabétique par l'api
                        // on sait quil y a au moins une route client a afficher => on l'affiche direct  => mais on vérifie si le routeur accepte cette route avant
                        // si non on va rien faire et laisser l'utilisateur sur le dashboard

                        // on vérifie si le routeur accepte cette route avant
                        const baseCliRoute = '/extranet/dash/' ;
                        const route2Go     = baseCliRoute + extranet_cli_routes[0].id;
                        const routeToCheck = route2Go;
                        const routeExists  = allRoutes.some(route => route.path === routeToCheck);

                        if (routeExists) {
                            // console.log(`La route extranet client ${routeToCheck} existe dans votre application.`);
                            this.$router.push(route2Go);
                        } /*else {
                            console.log(`La route extranet client ${routeToCheck} n'existe pas dans votre application.`);
                        }*/

                        this.loading = false;
                        return;

                    // si non on va rien faire et laisser l'utilisateur sur le dashboard
                    } else {
                        this.loading = false;
                        return;
                    }
                }
                this.loading = false;
                return;
            },
            immediate: true
        }
    },
    data() {
        return {
            loading: true,
        };
    },
};
</script>