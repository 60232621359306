/* eslint-disable */
import Layout from "@/layout/Layout.vue";

const automaticdebitsRoutes = {
  path: "/automaticdebits",
  name: "automaticdebits",
  component: Layout,
  // redirect: '/automaticdebits',
  alwaysShow: true,
  meta: {
      title: 'Prélèvements auto.',
      icon: 'euro',
      permissions: ['view prlv menu'],
      noCache: true,
  },
  children: [
        // Voir les prlv. en attente et la liste des remises
        {
            path: "/automaticdebits/dash",
            name: "Prélèvements automatiques",
            meta: { title: "Voir tout", icon: 'info-circle-solid', noCache: true, permissions: ['view prlvall menu'] },
            component: () => import( "@/views/automaticdebits/Dash.vue" ),
            hidden: false,
        },

        // édition des premières pages des factures + export csv des factures pour la compta
        {
            path: "/automaticdebits/docs",
            name: "Documents",
            meta: { title: "Documents ts. clients", icon: 'documentation', noCache: true, permissions: ['view prlvdoc menu'] },
            component: () => import( "@/views/automaticdebits/Docs.vue" ),
            hidden: false,
        },

        // voir 1 une remise de prlv en fonction d'un id
        {
            path: '/automaticdebits/show/:id_remise(\\d+)',
            component: () => import('@/views/automaticdebits/Show.vue'),
            name: 'Voir Remise',
            meta: { title: 'Remise', noCache: true, permissions: ['view remises details', 'view remises ope'] },
            hidden: true,
        },

        // Chorus - Voir et editer les premières pages des factures des clients sous chorus
        {
            path: "/automaticdebits/dashChorus",
            name: "chorus ",
            meta: { title: "Documents Chorus", icon: 'el-icon-wallet-filled', noCache: true, permissions: ['view chorus menu'] },
            component: () => import( "@/views/automaticdebits/dashChorus.vue" ),
            hidden: false,
        },
  ]
};

export default automaticdebitsRoutes;