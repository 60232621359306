<template>
    <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
            :unique-opened="true"
            :show-timeout="200"
            :default-active="$route.path"
            :collapse="isCollapse"
            mode="vertical"
            class="el-menu-vertical"
        >
            <router-link to="/">
                <li class="el-menu-item logo">
                    <el-image v-if="isCollapse"       src="/images/new-logo-rond-seul-transp.png" fit="fill"    alt="Logo Prosoluce" style="height: 40px; width: 40px; float: left; margin-left: -9px; margin-top: 0px;" />
                    <el-image v-else-if="!isCollapse" src="/images/logo-prosoluce-new-transp.png" fit="contain" alt="Logo Prosoluce" style="height: 45px; margin-left: auto; margin-top: -5px;" />
                </li>
            </router-link>
            <sidebar-item
                v-for="route in routes"
                :key="route.path"
                :item="route"
                :base-path="route.path"
                :isNest="isCollapse"
            />          
        </el-menu>
    </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
export default {
    name: "SideIndex",
    components: { SidebarItem },
    computed: {
        ...mapGetters(["sidebar", "permission_routers"]),
        routes() {
            // console.warn("THIS.$STORE.STATE.PERMISSION.ROUTES")
            // console.log(this.$store.state.permission.routes)
            return this.$store.state.permission.routes;
        },
        isCollapse() {
            return !this.sidebar.opened;
        }
    }
};
</script>

<style lang="sass">
.el-menu-vertical:not(.el-menu--collapse)
    min-height: 100vh
</style>
