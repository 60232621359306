/* eslint-disable */
import Layout from "@/layout/Layout.vue";

const prospectRoutes = {
  path: "/client",
  name: "client",
  component: Layout,
  redirect: '/client',
  alwaysShow: true,
  meta: {
      title: 'Prospects/Clients',
      icon: 'user',
      permissions: ['view client menu','view clientmngt menu','browse comptes'],
      noCache: true,
  },
  children: [
        // Voir la listes des clients
        {
            path: "/accounts",
            name: "comptes",
            meta: { title: "Liste des comptes", icon: 'education', noCache: true, permissions: ['view clientmngt menu','browse comptes'] },
            component: () =>
                import(/* webpackChunkName: "about" */ "@/views/accounts/Accounts.vue")
        },

        // voir/éditer 1 compte en fonction d'un id
        {
            path: '/account/show/:id(\\d+)',
            component: () => import('@/views/accounts/showAccount.vue'),
            name: 'showAccount',
            meta: { title: 'Show Compte', noCache: true, permissions: ['view dash comptes', 'view comptes'] },
            hidden: true,
        },
  ]
};

export default prospectRoutes;