/* eslint-disable */
import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // get token from cookie
import Resource from "@/api/resource";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

const DEFAULT_TITLE = "Prosoluce";

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  document.title = to.meta.title ? to.meta.title + " - " + DEFAULT_TITLE : DEFAULT_TITLE;

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {

    // ici on navigue dans différentes url du front
    // dans request.js on traite le cas d'un token expiré pour chaque appel API mais la navigation front (router) est indépandante de l'API
    // donc un utilisateur peut naviguer sur le front-end ET être expiré tant qu'il n'y a aps d'appel API pour signaler l'expiration
    // Idée => faire une requête api très simple pour tester le token
    // et on laisse request.js rediriger vers la page de login si besoin
    const remiseResource = new Resource("protected");
    await remiseResource.list();

    console.log("avec token");
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
        // en attendant les roles
        /*
        next();
        NProgress.done();
        */
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      console.log('hasRoles');
      console.log(hasRoles);
      if (hasRoles) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['manager','editor']
          const { roles, permissions } = await store.dispatch('user/getInfo');

          // console.log("ROLE : "+ roles[0]);
          // console.log("PERMISSIONS : " + permissions);

          // generate accessible routes map based on roles
          // const accessRoutes = await store.dispatch('permission/generateRoutes', roles, permissions);
          store.dispatch('permission/generateRoutes', { roles, permissions }).then(response => {
            // dynamically add accessible routes

            // suite à la maj vers vue router ^3.5 
            // router.addRoutes() is deprecated and has been removed in Vue Router 4. Use router.addRoute() instead.
            response.forEach(function(route){
              router.addRoute(route);
            })

            // hack method to ensure that addRoutes is complete
            // set the replace: true, so the navigation will not leave a history record

            next({ ...to, replace: true });
          });
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');
          Message.error(error || 'Has Error');
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/
    console.log("sans token");

    if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : '') !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
