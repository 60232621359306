import Vue from "vue";
import {
    Alert,
    Aside,
    Autocomplete,
    Backtop,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    Col,
    Collapse,
    CollapseItem,
    Container,
    Checkbox,
    CheckboxGroup,
    Descriptions,
    DescriptionsItem,
    DatePicker,
    Dialog,
    Divider,
    Drawer,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Empty,
    Form,
    FormItem,
    Header,
    Image,
    Input,
    InputNumber,
    Link,
    Loading,
    Main,
    Menu,
    MenuItem,
    Message,
    MessageBox,
    Notification,
    Option,
    PageHeader,
    Pagination,
    Popover,
    Progress,
    Radio,
    RadioButton,
    RadioGroup,
    Result,
    Row,
    Scrollbar,
    Select,
    Skeleton,
    SkeletonItem,
    Slider,
    Step,
    Steps,
    Submenu,
    Switch,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    TimelineItem,
    Tooltip,
    Transfer,
    Tree,
    Upload,
} from "element-ui";
import lang from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";

locale.use(lang);
Vue.prototype.$message      = Message;
Vue.prototype.$notification = Notification;
Vue.prototype.$alert        = MessageBox.alert
Vue.prototype.$confirm      = MessageBox.confirm
Vue.prototype.$prompt       = MessageBox.prompt

Vue.use(Alert);
Vue.use(Aside);
Vue.use(Autocomplete);
Vue.use(Backtop);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Card);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Container);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Empty);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Header);
Vue.use(Image);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Link);
Vue.use(Loading);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
// Vue.use(MessageBox); // les méthodes de MessageBox sont importés unes à unes au dessus
Vue.use(Option);
Vue.use(PageHeader);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Result);
Vue.use(Row);
Vue.use(Scrollbar);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Slider);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Submenu);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TimePicker);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Tooltip);
Vue.use(Transfer);
Vue.use(Tree);
Vue.use(Upload);
