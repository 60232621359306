/* eslint-disable */
import { login, logout, getInfo } from '@/api/auth';
import { getToken, setToken, removeToken } from '@/utils/auth';
import router, { resetRouter, asyncRoutes } from '@/router';
import store from '@/store';

import Layout from '@/layout/Layout.vue';

const state = {
  id: null,
  token: getToken(),
  name: '',
  prenom: '',
  avatar: '',
  roles: [],
  permissions: [],
  isadmin: false,
  extranet_routes: [],
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_PRENOM: (state, prenom) => {
    state.prenom = prenom;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ISADMIN: (state, isadmin) => {
    state.isadmin = isadmin;
  },
  SET_EXTRANET_ROUTES: (state, extranet_routes) => {
    state.extranet_routes = extranet_routes;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { email, password } = userInfo;
    // console.log('n1.user.login' + email + password);
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password })
        .then(response => {
          if(this.$node_env == 'development'){
            console.log('n3.tokken' + response.meta.access_token);
            console.log('n3b.response' + JSON.stringify(response));
          }
          commit('SET_TOKEN', response.meta.access_token);
          setToken(response.meta.access_token);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          console.log('p1.response' + JSON.stringify(response));
          const { data } = response;

          if (!data) {
            reject('Verification failed, please Login again.');
          }

          const { roles, name, prenom, avatar, permissions, id, extranet_routes } = data;
          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('getInfo: roles must be a non-null array!');
          }

          commit('SET_ROLES', roles);
          if (roles.includes('super admin')){
            commit('SET_ISADMIN', true);
          }
          commit('SET_PERMISSIONS', permissions);
          commit('SET_NAME', name);
          commit('SET_PRENOM', prenom);
          commit('SET_AVATAR', avatar);
          commit('SET_ID', id);

          // ajout dynamique des routes extranet si il y en a
          if ( extranet_routes && extranet_routes != null && extranet_routes.length > 0 ) {
            
            commit('SET_EXTRANET_ROUTES', extranet_routes);

            var grp_icon          = 'tree-table';
            var cli_icon          = 'el-icon-office-building';
            var add_gestion_group = false;

            // la liste des comptes visibles et l'accès aux informations de ces même comptes est gérer par l'API
            // on boucle sur les clients/groupe de clients
            // pour construire la route en fonction des informations qu'on a
            for (const key in extranet_routes) {
              if (Object.hasOwnProperty.call(extranet_routes, key)) {

                
                const compte_cli = extranet_routes[key];
                
                // console.warn("Laaaaaa");
                // console.warn(compte_cli);

                // si il s'agit d'un groupe de client(s) et que le groupe n'est pas vide
                if( compte_cli.is_group !== false && compte_cli.is_group.comptes && Object.values(compte_cli.is_group.comptes).length > 0 ){

                  add_gestion_group = true;

                  var dynamic_childs_of_groups = [];
                  const comptes                = compte_cli.is_group.comptes;

                  // ici on s'est retrouvé face a un problème concernant les routes "asynchrone" (soumises aux droits)
                  // l'ajout dynamique des routes d'extranet s'effectue peu importe les routes existantes
                  // donc au chargement de l'appli les routes s'ajoute MAIS, si le user se relog, les routes sont de nouveau ajoutées en double
                  // Pour contourner ça, on boucle sur les routes asynchrones existantes, et si il y a doublon on la suprime
                  for( const ka in asyncRoutes){
                    if ( asyncRoutes[ka].path == ('/extranet/grp/' + compte_cli.id) ){
                      // console.warn("La route groupe ("+compte_cli.id+") existe déjà");
                      delete asyncRoutes[ka];
                    }
                  }

                  // on boucle sur les groupes pour en extraire les comptes
                  for (const key in comptes) {
                    if (Object.hasOwnProperty.call(comptes, key)) {
                  
                      const sous_compte_cli = comptes[key];
                
                      // on stocke les infos du comptes
                      dynamic_childs_of_groups.push(
                        {
                          path     : '/extranet/dash/' +  sous_compte_cli.id,
                          component: () => import( "@/views/extranet/dashBoard" ),
                          // name      : sous_compte_cli.nom_aff.replace(/\s/g, ''),   // pour le breadcrum -> commenté car semble afficher un warning en console
                          meta     : {
                            title  : sous_compte_cli.nom_aff || 'n/c',   // nom du client
                            noCache: true,
                            icon   : cli_icon,
                            roles  : ['extranet client'],
                            noCache: true
                          },
                          hidden: false,
                        }
                      );
                
                    }
                  }

                  const title = compte_cli.nom_aff ? 'Vue globale : ' + compte_cli.nom_aff : 'Vue globale';

                  // création du menu groupe puis ajout des comptes dans children et ajout dans les routes soumises a permissions
                  asyncRoutes.push({
                    path      : '/extranet/grp/' + compte_cli.id,
                    component : Layout,
                    // name      : compte_cli.nom_aff.replace(/\s/g, ''),   // pour le breadcrum -> commenté car semble afficher un warning en console
                    meta      : {
                      is_group: true,
                      title   : title.replace(/(.{30})..+/, "$1..."),   // nom du groupe
                      roles   : ['extranet client'],
                      icon    : grp_icon,
                    },
                    children: dynamic_childs_of_groups,
                  })
                  



                } else {
                  
                  // explications ligne 115
                  for( const k in asyncRoutes){
                    if ( asyncRoutes[k].path == ('/extranet/dash/' + compte_cli.id) ){
                      // console.warn("La route client ("+compte_cli.id+") existe déjà");
                      delete asyncRoutes[k];
                    }
                  }

                  // Routes "normales" ajoutées dans les routes soumises a permissions
                  asyncRoutes.push(
                    {
                      path     : '/extranet/dash/' + compte_cli.id,
                      component: Layout,
                      // name     : 'dashExtranet' + compte_cli.id,      // pour le breadcrum -> commenté car semble afficher un warning en console
                      meta     : {
                        title  : compte_cli.nom_aff || 'n/c',    // nom du client
                        roles  : ['extranet client'],
                        noCache: true,
                      },

                      // no submenu, because children.length == 1
                      children: [
                        {
                          path: '/extranet/dash/' + compte_cli.id,
                          meta: {
                            title  : compte_cli.nom_aff || 'n/c',    // nom du client
                            icon   : cli_icon,
                            noCache: true,
                            roles  : ['extranet client'],
                          },
                          component: () => import( "@/views/extranet/dashBoard" ),
                        }
                      ],
                    }
                  );
                }
              }
            }

            // ce bloc s'ajoute si des routes de groupe extranet existent et permet la navigation vers l'affichage du groupe en question via son ID
            // on affiche les factures, la téléphonie. . .
            if ( add_gestion_group ){
              asyncRoutes.push(
                {
                  path: '/extranet/dashGroup/:id_grp(\\d+)',
                  // name: "Gestion de groupe",
                  component: Layout,
                  hidden: true,
                  meta: {
                      title: 'Gestion de groupe',
                      // icon: 'user',
                      roles  : ['extranet client'],
                      noCache: true,
                  },
                  children: [
                    {
                        path: '/extranet/dashGroup/:id_grp(\\d+)',
                        component: () => import( "@/views/extranet/dashGroup" ),
                        name: 'showAccount',
                        meta: { title: 'Gestion Groupe', noCache: true },
                        hidden: true,
                    },
                  ]
                }
              );
            }
          } else {
            commit('SET_EXTRANET_ROUTES', []);
          }

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      console.log('out1.user.logout');
      logout(state.token)
        .then(() => {
          console.log('out3.user.logout');
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          removeToken();
          resetRouter();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
