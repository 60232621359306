/* eslint-disable */
import axios from 'axios';
import { Message } from 'element-ui';
import { MessageBox } from 'element-ui';
import { getToken, setToken , removeToken } from '@/utils/auth';
// import router, { resetRouter } from '@/router';

// Create axios instance
const service = axios.create({
  baseURL: '',
  timeout: 10000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {

    // si on a un timeout spécifique pour la requete
    if (config.override_timeout){
      config.timeout = config.override_timeout
    }

    // si on a un responseType spécifique pour la requete (utile pour des requetes PDF)
    if (config.override_responseType){
      config.responseType = config.override_responseType;
    }

    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
    }

    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization);
      response.data.token = response.headers.authorization;
    }

    return response.data;
  },
  error => {
    let message = error.message;
    if (error.response.data && error.response.data.errors) {
      // messages dans validation form
      message = "Vérifiez les champs";
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }
    
    // si on a dans la reponse le champ type == 'login' alors c'est une erreur de mail/MdP
    if ( error.response.data.type  && error.response.data.type == 'login' ) {
      message = "Identifiant ou mot de passe incorrect";
    }
    
    // si on a dans la reponse le champ message == 'Unauthenticated.' alors c'est une erreur d'user dejà délogué
    if ( error.response.data.message && error.response.data.message == 'Unauthenticated.' ) {
      removeToken();
      MessageBox.alert('Vous allez être redirigé vers la page de connexion', 'Session expirée', {
        confirmButtonText: 'OK',
        callback: () => {
          // récupère qqc comme ça : "/profile/edit"
          // ce paramètre est passé pour permettre la redirection après le login
          const base = window.location.pathname;
          document.location.assign(base);
        }
      });


    }

    return Promise.reject(error);
  },
);

export default service;
