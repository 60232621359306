import Vue from "vue";

// List here : https://pictogrammers.com/library/mdi/
import MenuIcon from 'vue-material-design-icons/Menu.vue'; // https://www.npmjs.com/package/vue-material-design-icons
import HistoryIcon from 'vue-material-design-icons/History.vue';
import ClockEditOutlineIcon from 'vue-material-design-icons/ClockEditOutline.vue';
import TrashCanOutlineIcon from 'vue-material-design-icons/TrashCanOutline.vue';
import MapPlusIcon from 'vue-material-design-icons/MapPlus.vue';
import MapSearchIcon from 'vue-material-design-icons/MapSearch.vue';
import AccountArrowLeftOutlineIcon from 'vue-material-design-icons/AccountArrowLeftOutline.vue';
import CalendarMultiselectOutlineIcon from 'vue-material-design-icons/CalendarMultiselectOutline.vue';
import CodeJsonIcon from 'vue-material-design-icons/CodeJson.vue';
import FormatListNumberedIcon from 'vue-material-design-icons/FormatListNumbered.vue';
import PencilPlusOutlineIcon from 'vue-material-design-icons/PencilPlusOutline.vue';

Vue.component('menu-icon', MenuIcon);
Vue.component('history-icon', HistoryIcon);
Vue.component('clock-edit-outline-icon', ClockEditOutlineIcon);
Vue.component('trash-can-outline-icon', TrashCanOutlineIcon);
Vue.component('map-plus-icon', MapPlusIcon);
Vue.component('map-search-icon', MapSearchIcon);
Vue.component('account-arrow-left-outline-icon', AccountArrowLeftOutlineIcon);
Vue.component('calendar-multiselect-outline-icon', CalendarMultiselectOutlineIcon);
Vue.component('code-json-icon', CodeJsonIcon);
Vue.component('format-list-numbered-icon', FormatListNumberedIcon);
Vue.component('pencil-plus-outline-icon', PencilPlusOutlineIcon);