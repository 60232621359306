const getters = {
    sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    // visitedViews: state => state.tagsView.visitedViews,
    // cachedViews: state => state.tagsView.cachedViews,
    userId: state => state.user.id,
    token: state => state.user.token,
    // avatar: state => state.user.avatar,
    name: state => state.user.name,
    prenom: state => state.user.prenom,
    // introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    permission_routes: state => state.permission.routes,
    addRoutes: state => state.permission.addRoutes
};
export default getters;
