import Layout from '@/layout/Layout.vue';

const perm = ['view renew menu'];

const renouvelementRoutes = {
  path: '/renouvelement',
  component: Layout,
  name: 'Renouvèlement',  // pour le breadcrum  // ce mot possède deux orthographes correctes : renouvellement et renouvèlement. : https://dictionnaire.orthodidacte.com/article/orthographe-renouvellement
  meta: {
    title: 'Renouvèlement',
    permissions: perm,
  },

  // no submenu, because children.length == 1
  children: [
    // Accéder à la page de renouvèllement des services
    {
      path: '/renouvelement',
      component: () => import('@/views/renouvelement/DashRenouvelement'),
      meta: {
        title: 'Renouvèlement',
        icon: 'el-icon-refresh',
        permissions: perm,
        noCache: true,
      },
    }
  ],
};

export default renouvelementRoutes;