<template>
    <div class="app-wrapper" :class="classObj">
        <el-container>
            <el-aside :width="'unset'">
                <!-- see node_modules/element-ui/packages/aside/src/main.vue -->
                <sidebar class="sidebar-container" />
            </el-aside>
            <el-container>
                <el-header>
                    <navbar />
                </el-header>
                <app-main />
            </el-container>
        </el-container>
    </div>
</template>
<script>
// import RightPanel from '@/components/RightPanel';
import { AppMain, Sidebar, Navbar } from "./components";
import { mapGetters } from "vuex";

export default {
    name: "LayoutView",
    components: {
        AppMain,
        Sidebar,
        Navbar
    },
    computed: {
        ...mapGetters(["sidebar"]),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                // withoutAnimation: this.sidebar.withoutAnimation,
                // mobile: this.device === 'mobile',
                sidebarcollapsed: !this.sidebar.opened
            };
        }
    }
};
</script>


<style>
  .el-header {
    /* background-color: #B3C0D1;
    color: #333;
    text-align: center; */
    height: 50px;
    z-index: 10;
  }

</style>
