/* eslint-disable */
import request from '@/utils/request';

export function loginfree(data) {
    // console.log('n2.util.login' + data.email);
    return request({
            url: '/api/free',
            method: 'get'
    });
}

export function login(data) {
    // console.log('n2.util.login' + data.email);
    return request({
            url: '/api/auth/login',
            method: 'post',
            data: data,
    });
}

export function getInfo(token) {
    console.log('getInfo auth.js');
    return request({
        url: '/api/user',
        method: 'get',
    });
}

export function logout() {
    console.log('out2.api.logout');
    return request({
        url: '/api/auth/logout',
        method: 'get',
    });
}

export function test() {
    console.log('protected route');
    return request({
        url: '/api/protected',
        method: 'get',
    });
}
