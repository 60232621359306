<template>
    <div :style="'min-height:' + (size ? size : '100') + 'px !important'">
    </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "MarginFooterComponent",
  props: {
    size: {
      type: Number,
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style >
</style>
