/* eslint-disable */
import store from '@/store';

export const checkPerms = (value) => {

  const prems = store.getters && store.getters.permissions;

  const roles = store.getters && store.getters.roles;
  if(roles.includes('super admin')) return true;

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const Perms = value;

      const hasPerm = prems.some(perm => {
        return Perms.includes(perm);
      })

      return hasPerm;

    } else {
      console.warn(`\checkPerms need no empty roles! Like "['view permissions','view users']"\n`);
      return false;
    }
  } else {
    console.warn(`\checkPerms need roles! Like "['view permissions','view users']"\n`);
    return false;
  }
}